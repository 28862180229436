.card,
.jumbotron,
.nav-item .nav-link {
	border-radius: 0.15rem;
}

p {
	text-align: justify;
}

@include media-breakpoint-down(sm) {
	.display-3 {
		font-size: 3rem;
	}
}

@include media-breakpoint-up(lg) {
	main.content {
		padding-top: .75rem;
		padding-left: 2.5rem;
	}
}

h2 {
	margin-top: 1.25rem;
}

.img-fluid {
	margin-bottom: 1rem;
}

.table .img-fluid {
	margin-bottom: 0;
}

.strikethrough {
	position: relative;
	display: inline-block;
}

.strikethrough::before {
	content: '';
	width: 100%;
	position: absolute;
	left: 0px;
	top: 50%;
	border-bottom: 1px solid black;
	-webkit-transform: skewY(-35deg);
	transform: skewY(-35deg);
}

td ul {
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

ul, ol {
	margin-bottom: 20px;
	li {
		line-height: 2;
	}
}
