ol.summary {
	padding-left: 1.5rem;

	li {
		font-size: 1.2em;
		padding-left: 1.2em;
		line-height: 1.5em;
		p {
			font-size: 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		list-style-type: none;
		padding-left: 0;

		li {
			padding-left: 0;
			padding-bottom: 1rem;
			a.btn {
				display: block;
				float: none !important;
				margin-bottom: 0.5rem;
				// white-space: normal;
			}
		}
	}
}
