.section-nav {
	> ul {
		border-left: 1px solid rgba(0, 0, 0, 0.125);
	}

	position: sticky;
	position: -webkit-sticky;
	height: calc(100vh - 4rem);
	top: 4rem;
	overflow-y: auto;

	padding-top: 1.5rem;
	padding-bottom: 1.5rem;

	ul {
		font-size: .9rem;
		list-style: none;
		padding-left: 1.25rem;

		li {
			padding-bottom: .25em;
			a {
				text-decoration: none;
				color: #9E9E9E;
				&:hover {
					color: $primary;
					text-decoration: none;
				}
			}
		}
	}
}


// due to sticky header, this ensures that it jumps to correct position
main {
	h2[id],
	h3[id] {
		&::before {
			display: block;
			height: 4.5rem;
			margin-top: -4.5rem;
			visibility: hidden;
			content: "";
		}
	}
}
