nav.sidebar {
	padding: 10px;
	overflow: auto;

	.nav-header {
		padding: 0.3rem;
		padding-top: 0.7rem;
		border-bottom: 1px solid $light;
	}

	.nav-list {
		padding-left: 1rem;
		font-weight: 400;

		.nav-link {
			padding: 0.375rem .75rem;
			margin: 0;
			line-height: 1.25rem;
			font-weight: 400;
			padding-bottom: .5em;
			padding-top: .5em;

			&.active {
				background-color: $secondary;
			}
		}
	}

	.nav-link {
		color: black;
		margin: .25em 0;
		font-weight: 600;

		&.disabled {
			color: transparentize(black, 0.325);
		}

		&:not(.active):hover {
			background-color: $light;
		}

		&.active {
			background-color: $secondary;
			color: black;
		}
	}

	.sidebar-container {
		width: 100%;
	}

	// Queries
	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 4rem;
		height: calc(100vh - 4rem);
		border-right: 1px solid transparentize(black, 0.925);
	}

	@include media-breakpoint-down(lg) {
		margin-bottom: 0.5rem;
		button {
			margin-left: auto;
		}

		.nav-list .nav-link {
			padding-bottom: .5rem;
			padding-top: .5rem;
			margin-bottom: .25rem;
		}
	}
}
