header.navbar {
	padding-top: 0;
	padding-bottom: 0;

	@include media-breakpoint-up(lg) {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.navbar-dark {
	color: #fff;
}

.header-title {
	a {
		color: #fff;
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}

	float: left;
	margin-bottom: 0;
	padding: 0.5rem 1rem;

	@media (max-width: 575.98px){
		font-size: 2rem;
	}
}


// Right side
%top-right-menu-element {
	margin-right: 1em;
	float: right;
}

// Text
.header-text {
	@extend %top-right-menu-element;
	letter-spacing: 2px;
	margin-top: 0.85em;
	font-size: 1.25rem;
	display: none;

	@include media-breakpoint-up(lg) {
		display: inline;
	}
}

// Toggler
button.navbar-toggler {
	@extend %top-right-menu-element;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	@include media-breakpoint-up(lg) {
		margin-top: 0.35em;
	}
}
