.table-padding {
	// min-width: 30%;
	// max-width: 20vw;
	th, td {
		padding-left: 0.5rem;
	}
}

.table {
	.thead-primary th {
		color: black;
		background-color: $secondary;
		border-color: transparentize($secondary, 0.625);
	}

	td {
		vertical-align: middle;
	}

}


table.fraction {
	td {
		padding: 0;
		border-top: 0;
		line-height: 1.3em;
		white-space: nowrap;
		// padding-top: 5px;
	}

	&.divider {
		tr:first-child td:first-child {
			padding-bottom: 2px;
			border-bottom: 1px solid black;
		}
	}
}
