%fact {
	border: 1px solid black;
	border-radius: .125rem;
	border-left: 4px solid black;
	padding: 0.5rem 1rem;
	margin-bottom: 1rem;
}

.fact-danger {
	@extend %fact;
	border-color: $danger;
}

.fact-fun {
	@extend %fact;
	border-color: $primary;
}

.fact-success {
	@extend %fact;
	border-color: $success;
}
